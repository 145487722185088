import {axiosInstance } from "../utils";
import $ from "jquery";

export function validateEmailText(emailtext:string) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailtext.match(validRegex)) {
      return 'pass';
    } else {
      return 'fail';
    }
}

export async function sendWaitroomEmailCode(useremail:string,gameid:any) {
    var addData:any = new Object();
    addData.email = encodeURIComponent(useremail);
    addData.gameID = encodeURIComponent(gameid);
    let user_status = 'new';
    var email_sent_response = await axiosInstance.post('easyplay/user/signup_waitroom/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                if(save_result[0]['status'].indexOf('registered') >= 0) {
                    user_status = 'registered';
                }
                return {'status':'email_sent','user_status':user_status};
            } else {
                return {'status':'email_error','user_status':user_status};
            }
        } else {
            return {'status':'lookup_error','user_status':user_status};
        }
    }).catch(error => {
        return {'status':'server_error','user_status':user_status};
    });

    return email_sent_response;
}

export async function checkLoginEmailCode(useremail:string,emailcode:string,userstatus:string,referrer:string) {
    
    var addData:any = new Object();
    addData.email = encodeURIComponent(useremail);
    addData.emailCode = encodeURIComponent(emailcode);
    addData.referrer=encodeURIComponent(referrer);

    let endpoint_url = 'easyplay/user/signup_confirm/';
    if(userstatus == 'registered') {
        endpoint_url = 'easyplay/user/login_confirm/';
    }

    var email_code_check_response = await axiosInstance.post(endpoint_url,addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                if(save_result[0]['status'] == 'expired') {
                    return {'status':'email_code_expired'};
                } else if(save_result[0]['status'] == 'pending') {
                    return {'status':'email_code_wrong'};
                } else if (save_result[0]['status'] == 'approved_waitroom') {
                    return {'status':'confirmed_waitroom'};
                } else {
                    // Existing User - Approve Code
                    let userid = save_result[0]['user_id'];
                    let passid = save_result[0]['playpass_id'];
                    let logintime = save_result[0]['time'];
                    let logintoken = save_result[0]['token'];

                    var login_creds = {
                        'userid':userid,
                        'passid':passid,
                        'logintime':logintime,
                        'logintoken':logintoken}
                    return {'status':'confirmed_existing','login_info':login_creds};
                }
            } else {
                return {'status':'lookup_error'};
            }
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return email_code_check_response;
}

export async function sendWaitroomWalletCheck(walletaddress:string,emailflag:number,referrer:string) {
    var addData:any = new Object();
    addData.wallet = encodeURIComponent(walletaddress);
    if(emailflag == 1) {
        addData.emailFlag = encodeURIComponent(1);
    }
    addData.referrer=encodeURIComponent(referrer);

    var wallet_sent_response = await axiosInstance.post('easyplay/user/wallet_confirm/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                // Existing User - Approve Code
                let userid = save_result[0]['user_id'];
                let passid = save_result[0]['playpass_id'];
                let logintime = save_result[0]['time'];
                let logintoken = save_result[0]['token'];

                var login_creds = {
                    'userid':userid,
                    'passid':passid,
                    'logintime':logintime,
                    'logintoken':logintoken}
                
                if(save_result[0]['status'] == 'register_email') {
                    return {'status':'register_email','login_info':login_creds};
                } else {
                    return {'status':'confirmed_existing','login_info':login_creds};
                }
            } else {
                return {'status':'wallet_error'};
            }
        } else {
            return {'status':'lookup_error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return wallet_sent_response;
}

export async function createRedirectToken(userid:any) {
    let stringfront = (Math.random() + 1).toString(36).substring(7);
    let stringend = (Math.random() + 1).toString(36).substring(7);
    let hashed_userid = stringfront+'u7t'+userid+'b4k'+stringfront;

    var addData:any = new Object();
    addData.redirectToken = encodeURIComponent(hashed_userid);

    let redirect_token = axiosInstance.post('member/redirect_link/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var info_result = result.data;
            if(info_result[0]['status'] == 'new') {
                return {'status':'new','token':info_result[0]['token']};
            } else {
                return {'status':'error'};
            }
        } else {
            return {'status':'error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return redirect_token;
}

export async function updateUserGameSetup(userid:any,email:any,walletaddress:any,username:any) {
    var addData:any = new Object();
    addData.userID = encodeURIComponent(userid);
    addData.wallet = encodeURIComponent(walletaddress);
    addData.email = encodeURIComponent(email);
    addData.userName = encodeURIComponent(username);


    var wallet_sent_response = await axiosInstance.post('easyplay/user/account_update/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                let final_status = 'success';
                if(save_result[0]['status'] == 'username_taken') {
                    final_status = 'username_taken';
                } else if(save_result[0]['status'] == 'email_taken') {
                    final_status = 'email_taken';
                } else if(save_result[0]['status'] == 'wallet_taken') {
                    final_status = 'wallet_taken';
                }
                return {'status':final_status };
            } else {
                return {'status':'data_error'};
            }
        } else {
            return {'status':'lookup_error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return wallet_sent_response;
}

export async function sendEmailUpdateCodes(userid:any, useremailnew:string) {
    var addData:any = new Object();
    addData.userID = encodeURIComponent(userid);
    addData.email = encodeURIComponent(useremailnew);

    var email_confirm_response = await axiosInstance.post('easyplay/user/email_code/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                let final_status = 'success';
                if(save_result[0]['status'] == 'email_taken') {
                    final_status = 'email_taken';
                }
                return {'status':final_status };
            } else {
                return {'status':'data_error'};
            }
        } else {
            return {'status':'lookup_error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return email_confirm_response;
}

export async function checkEmailUpdateCodes(userid:any, useremailnew:string, emailcodeold:string, emailcodenew:string) {
    var addData:any = new Object();
    addData.userID = encodeURIComponent(userid);
    addData.email = encodeURIComponent(useremailnew);
    addData.emailCode1 = encodeURIComponent(emailcodeold);
    addData.emailCode2 = encodeURIComponent(emailcodenew);

    var email_confirm_response = await axiosInstance.post('easyplay/user/email_confirm/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                let final_status = 'success';
                if(save_result[0]['status'] == 'email_taken') {
                    final_status = 'email_taken';
                } else if(save_result[0]['status'] == 'code_wrong') {
                    final_status = 'email_code_wrong';
                }
                return {'status':final_status };
            } else {
                return {'status':'data_error'};
            }
        } else {
            return {'status':'lookup_error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return email_confirm_response;
}