import $ from "jquery";
import { axiosInstance } from "./axios.helper";

export {LocalStorage} from "./localStorage";
export {axiosInstance} from "./axios.helper";
export {
  makeOnchainPayment, 
  changeWalletNetwork, 
  getWalletBalance, 
  getChainNetworkName } from "./payments";

export const shortenAddress = (address:string) => {
  return `${address.slice(0, 4)}...${address.slice(
    address.length - 4,
    address.length
  )}`;
};

export function closeAllModals() {
  var modalelems = document.querySelectorAll('.modal');
  for(var i = 0; i < modalelems.length;i++) {
      var modal_instance = window.M.Modal.getInstance(modalelems[i]);
      if(modal_instance) {
          modal_instance.close();
      }
  }
}

export function startPageModals() {
  var model_elems = document.querySelectorAll('.modal');
  var model_instances = window.M.Modal.init(model_elems,{'opacity':0.75,onOpenStart: function(){
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          $(".modal").css({'width':'95%'});
      } else {
          $(".modal").css({'width':'50%'});
      }
  }, onCloseEnd: function(){
      //
  }});
}

export function resetCenterHeight() {
  var tmptxtheight:any = $("#splash-main").height();
  var tmpwindowheight:any = $(window).height();
  if(tmpwindowheight > (tmptxtheight+100)) {
      var newtop = ((tmpwindowheight - tmptxtheight - 50) / 2);
      $("#splash-main").css({'top':newtop+'px'});
  } else {
      $("#splash-main").css({'top':'50px'});
  }
}

export function resetBodyWidth() {
  $("body").css({'overflow':'auto','overflow-x':'hidden'});
  resetCenterHeight();
}

export function validateEmailText(emailtext:string) {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (emailtext.match(validRegex)) {
    return 'pass';
  } else {
    return 'fail';
  }
}

export function timestampToText(timestamp:any,multiplier:any) {
  var time_date = new Date((timestamp*multiplier));
  var time_date_text = time_date.getUTCFullYear()+'-';
  if(time_date.getUTCMonth() < 10) {
    time_date_text = time_date_text+'0';
  }
  time_date_text = time_date_text+time_date.getUTCMonth()+'-';
  if(time_date.getUTCDate() < 10) {
    time_date_text = time_date_text+'0';
  }
  time_date_text = time_date_text+time_date.getUTCDate()+' ';
  if(time_date.getUTCHours() < 10) {
    time_date_text = time_date_text+'0';
  }
  time_date_text = time_date_text+time_date.getUTCHours()+':';
  if(time_date.getUTCMinutes() < 10) {
    time_date_text = time_date_text+'0';
  }
  time_date_text = time_date_text+time_date.getUTCMinutes()+':';
  if(time_date.getUTCSeconds() < 10) {
    time_date_text = time_date_text+'0';
  }
  time_date_text = time_date_text+time_date.getUTCSeconds();

  return time_date_text;
}

export function dateSlashToDash(datestring:string) {
  var dashdate = '';
  var datesplit = datestring.split("/");
  if(datesplit.length == 3) {
      dashdate = datesplit[2]+'-'+datesplit[0]+'-'+datesplit[1];
  }

  return dashdate;
}

export function dateDashToSlash(datestring:string) {
  var slashdate = '';
  var datesplit0 = datestring.split("T");
  var datesplit = datesplit0[0].split("-");
  if(datesplit.length == 3) {
      slashdate = datesplit[1]+'/'+datesplit[2]+'/'+datesplit[0];
  }
  return slashdate;
}

export function dateDaysBetween(start:any,end:any,datetype:string) {
  var startdate;
  var enddate;
  if(datetype == 'text') {
      var datesplit1 = start.split("/");
      var datesplit2 = end.split("/");
      startdate = new Date(datesplit1[2],datesplit1[0]-1,datesplit1[1]);
      enddate = new Date(datesplit2[2],datesplit2[0]-1,datesplit2[1]);
  } else {
      startdate = start;
      enddate = end;
  }

  var date_diff = enddate.getTime() - startdate.getTime();
  var days_diff = Math.round(date_diff / (1000 * 3600 * 24));
  
  return days_diff;
}


export function randomNumberString(length:number) {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
      var nextnumber = characters.charAt(Math.floor(Math.random() * charactersLength));
      if((nextnumber == '0')&&(counter == 0)) {

      } else {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
      }
  }
  return result;
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}