import { BrowserProvider, Contract, formatUnits, encodeBytes32String, parseUnits, hexlify, keccak256, AbiCoder  } from 'ethers'

import { clearAccountSignOn } from "../redux/Account/actions";
import {axiosInstance, startPageModals, timestampToText} from "../utils";

import $ from "jquery";

const payment_contract_abi_base_sepolia = require("../utils/abis/payment_base_sepolia_abi.json");
const payment_contract_abi_base = require("../utils/abis/payment_base_abi.json");
const base_usdc_abi = require('../utils/abis/usdc_base_abi.json');


// ABI snippets needed for our interactions
const CONTRACT_ABI = [
    "function depositNonces(address account) view returns (uint256)",
    "function depositERC20(address tokenAddress, uint256 amount, bytes32 receipt)",
];

const ERC20_ABI = [
    "function approve(address spender, uint256 amount) returns (bool)",
    "function allowance(address owner, address spender) view returns (uint256)",
];

export function getChainNetworkName(chainID:number) {
    if(chainID == 84532) {
        return 'base_sepolia';
    } else if(chainID == 8453) {
        return 'base';
    } else if(chainID == 1) {
        return 'ethereum';
    } else if(chainID == 1284) {
        return 'moonbeam';
    }
    return '';
}


export async function changeWalletNetwork(wcModal:any, network:string) {
    try {
        if(network == 'base_sepolia') {
            await wcModal.switchNetwork(84532);
        } else if(network == 'base') {
            await wcModal.switchNetwork(8453);
        } else if(network == 'ethereum') {
            await wcModal.switchNetwork(1);
        } else if(network == 'moonbeam') {
            await wcModal.switchNetwork(1284);
        }
    } catch(e) {
        return 'revert';
    }

    return network;
}


export async function getWalletBalance(wcModal:any, walletAddress:string, network:string, currency:string) {
    let walletconnected:any = wcModal.getIsConnected();

    if (!walletconnected) throw Error('User disconnected');

    let current_provider:any = wcModal.getWalletProvider();
    let currency_address:any;
    let currency_abi:any = [];
    let currency_decimals = 18;
    let final_balance:any = 0;
    let final_currency = 'native';

    if (currency == 'USDC') {
        final_currency = currency;
        currency_decimals = 6;
        if(network == 'base') {
            currency_address = '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
            currency_abi = base_usdc_abi;
        } else if(network == 'moonbeam') {
            currency_decimals = 9;
        }
    }

    if(current_provider !== undefined) {
        let currency_balance:any = 0;
        const ethersProvider = new BrowserProvider(current_provider);
        if(final_currency == 'native') {
            currency_balance = await ethersProvider.getBalance(walletAddress);
        } else {
            const signer = await ethersProvider.getSigner();
            const currency_contract = new Contract(currency_address, currency_abi as any, signer);
            currency_balance = await currency_contract.balanceOf(walletAddress);
        }
        
        final_balance = formatUnits(currency_balance, currency_decimals);
    }

    return final_balance;
}


export async function makeOnchainPayment(wcModal:any, network:string, currency:string, amount:number, paymentID:string) {
    let final_info:any = {};
    final_info['status'] = 'failed';

    /* CHECK IF WALLET CONNECT IS CONNECTED */
    let walletconnected = wcModal.getIsConnected();
    if (!walletconnected) throw Error('User disconnected');
    let current_provider = wcModal.getWalletProvider();

    /* SETUP  CONTRACT AND CURRENCY ADDRESS AND ABI */
    let payment_contract_address:any = '';
    let payment_contract_abi:any = '';
    let currency_address:any = '';
    let currency_contract_abi:any = '';
    let currency_decimals = 18;

    if(network == 'base') {
        payment_contract_address = '0x02eFc32057F07F6652465e3907De5f5FF20c2776';
        payment_contract_abi = payment_contract_abi_base;
        if(currency == 'USDC') {
            currency_decimals = 6;
            currency_address = '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
            currency_contract_abi = base_usdc_abi
        } else {
            currency_address = ''
        }
    } else if(network == 'base_sepolia') {
        // OLD Contract payment_contract_address = '0xee7eb71bd40779f3400eaee37ed32150cc41faf3';
        payment_contract_address = '0xE43c487f79120172c09A08f0734B0b369fB91114';
        payment_contract_abi = payment_contract_abi_base_sepolia;
        currency_address = '';
    } 

    /* START TRANSACTION IF CONNECTED AND PROPER PAYMENT INFORMATION PASSED */
    let payment_transaction:any = null;
    if((current_provider !== undefined)&&(payment_contract_address.length > 0)&&(amount > 0)) {
        // Setup Final Amount In Proper Format
        let final_amount = parseUnits(amount.toString(),currency_decimals);

        // Setup Payment Contract
        const ethersProvider = new BrowserProvider(current_provider)
        const signer = await ethersProvider.getSigner()
        const payment_contract = new Contract(payment_contract_address, payment_contract_abi as any, signer);

        // Get the current nonce for the wallet address
        const nonce = await payment_contract.depositNonces(signer.address);

        /* SEND NATIVE CHAIN CURRENCY IF CURRENCY NOT SPECIFIED */
        if(currency_address.length > 0) {
            try{
                // Generate receipt hash
                const abiCoder = new AbiCoder();
                const encodedData = abiCoder.encode(
                    ['address', 'address', 'uint256', 'uint256'],
                    [signer.address, currency_address, final_amount, nonce]
                );
                const formatted_receipt = keccak256(encodedData);
                final_info['receipt'] = formatted_receipt;

                const currencyContract = new Contract(currency_address, currency_contract_abi, signer);
                const currentAllowance = await currencyContract.allowance(signer.address, payment_contract_address);

                let msg_element = document.getElementById("ep-payment-wallet-message");

                if (currentAllowance < final_amount) {
                    //console.log('Setting USDC allowance...');
                    
                    if(msg_element) {
                        msg_element.innerHTML = 'APPROVE AMOUNT ON WALLET . . .';
                    }
                    const approveTx = await currencyContract.approve(payment_contract_address, final_amount);
                    if(msg_element) {
                        msg_element.innerHTML = 'SETTING UP TRANSACTION . . .';
                    }
                    setTimeout(function(){
                        let msg_element = document.getElementById("ep-payment-wallet-message");
                        if(msg_element) {
                            msg_element.innerHTML = 'LOADING TRANSACTION . . .';
                        }
                    }, 4000);
                    await approveTx.wait();
                }

                payment_transaction = await payment_contract.depositERC20(currency_address, final_amount, formatted_receipt);
                if(msg_element) {
                    msg_element.innerHTML = 'WRAPPING UP . . .';
                }
                final_info['status'] = 'success';
                final_info['transaction_hash'] = payment_transaction['hash'];
            } catch(e:any) {
                final_info['status'] = 'rejected';
                console.log(e);
            }
        } else {
            try{
                // Get WETH address from contract
                const wethAddress = await payment_contract.WETH()

                // Create the receipt hash
                // OLD - let formatted_receipt:any = encodeBytes32String(paymentID);
                // keccak256(abi.encode(msg.sender, WETH, amount, depositNonces[msg.sender]))
                const formatted_receipt = keccak256(
                    AbiCoder.defaultAbiCoder().encode(
                        ['address', 'address', 'uint256', 'uint256'],
                        [signer.address, wethAddress, final_amount, nonce]
                    )
                );
                final_info['receipt'] = formatted_receipt;

                payment_transaction = await payment_contract.depositNative(formatted_receipt, {value: final_amount});
                final_info['status'] = 'success';
                final_info['transaction_hash'] = payment_transaction['hash'];
            } catch(e:any) {
                final_info['status'] = 'rejected';
            }
        }
    }

    return final_info;
}