import React from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import './App.css';
import {HeaderPublic} from "./components/organisms";
import {SplashMain, AccountPage, GameWidget} from "./components/pages";
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount } from '@web3modal/ethers/react';
// import { createAppKit } from '@reown/appkit/react'
// import { EthersAdapter } from '@reown/appkit-adapter-ethers'
// import { arbitrum, mainnet, moonbeam, base, baseSepolia, lightlinkPhoenix } from '@reown/appkit/networks'

import {isMobile} from "./utils"


const wcProjectId = 'e6ab724d38ea9f632c2a39cfda5ade69';
// const wcNetworks = [lightlinkPhoenix, mainnet, base, baseSepolia, moonbeam ];
// const wcMetadata = {
//   name: 'EasyPlay',
//   description: 'One Pass For Every Game. Easy.',
//   url: 'https://easyplay.co',
//   icons: ['https://embed.easyplay.co/android-icon-192x192.png']
// }

// const wcModal = createAppKit({
//   adapters: [new EthersAdapter()],
//   networks:[lightlinkPhoenix, mainnet, base, baseSepolia, moonbeam ],
//   metadata: wcMetadata,
//   projectId: wcProjectId,
//   features: {
//     analytics: true // Optional - defaults to your Cloud configuration
//   }
// })

const wcChains = [
  {
    chainId: 1890,
    name: 'Lightlink Phoenix Mainnet',
    currency: 'ETH',
    explorerUrl: 'https://phoenix.lightlink.io',
    rpcUrl: 'https://replicator.phoenix.lightlink.io/rpc/v1'
  },{
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
  },{
    chainId: 1284,
    name: 'Moonbeam',
    currency: 'GLMR',
    explorerUrl: 'https://moonscan.io/',
    rpcUrl: 'https://rpc.api.moonbeam.network'
  },{
    chainId: 8453,
    name: 'Base',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://mainnet.base.org'
  },
  // {
  //   chainId: 84532,
  //   name: 'Base Sepolia',
  //   currency: 'ETH',
  //   explorerUrl: 'https://sepolia.basescan.org/',
  //   rpcUrl: 'https://sepolia.base.org'
  // },
];
const wcMetadata = {
  name: 'EasyPlay',
  description: 'One Pass For Every Game. Easy.',
  url: 'https://easyplay.co',
  icons: ['https://embed.easyplay.co/android-icon-192x192.png']
}

let wcModal:any;

function App() {
  const [appState, setAppState] = useState(false);

  if(appState == false) {
      setAppState(true);

      let mobile_check = isMobile();

      if((window.location.href.indexOf('redirect') >= 0)) {
        const ethersConfig = defaultConfig({
          metadata: wcMetadata,
          /*Optional*/
          enableEIP6963: true, // true by default
          enableInjected: true, // true by default
          enableCoinbase: true, // true by default
          rpcUrl: '...', // used for the Coinbase SDK
          defaultChainId: 1, // used for the Coinbase SDK
          // auth: {
          //   email:false,
          //   socials:[]
          // }
        });

        wcModal = createWeb3Modal({
            ethersConfig,
            chains: wcChains,
            projectId: wcProjectId,
            enableAnalytics: true,
            // enableSwaps:false,
            // enableOnramp:false,
            // isSiweEnabled:true
        });
      } else {
        const ethersConfig = defaultConfig({
            metadata: wcMetadata,
            /*Optional*/
            enableEIP6963: true, // true by default
            enableInjected: true, // true by default
            enableCoinbase: false, // true by default
            rpcUrl: '...', // used for the Coinbase SDK
            defaultChainId: 8453, // used for the Coinbase SDK
            // auth: {
            //   email:false,
            //   socials:[]
            // }
        });

        wcModal = createWeb3Modal({
            ethersConfig,
            chains: wcChains,
            projectId: wcProjectId,
            enableAnalytics: true,
            // enableSwaps:false,
            // enableOnramp:false,
            // isSiweEnabled:true
        });
      } 
  }
  
  return (
    <div>
      <HashRouter basename="/">
        <Routes>
          {/* <Route path="/account" element={<AccountPage wcModal={wcModal}  />} />
          <Route path="/" element={<SplashMain wcModal={wcModal} />} />
          <Route path="*" element={<SplashMain wcModal={wcModal}  />} />
          <Route path="/widget" element={<GameWidget wcModal={wcModal}  />} /> */}

          <Route path="/" element={<SplashMain wcModal={wcModal} />} />
          <Route path="*" element={<SplashMain wcModal={wcModal}  />} />

          {/* <Route path="/" element={<GameWidget wcModal={wcModal} />} />
          <Route path="*" element={<GameWidget wcModal={wcModal}  />} /> */}

          
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
